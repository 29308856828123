.emptyStateWrapper {
  width: 15dvw;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.emptyStateText { 
  font-size: 18px;
  text-align: center;
}

.interviewTypeTableText {
  font-size: 16px;
  color: #4a0c3f;
  &:hover {
    cursor: pointer;
  }
}

