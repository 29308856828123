.wrapper {
  display: flex;
  justify-content: center;
}

.heroWrapper {
  justify-content: center;
  align-items: center;
  margin-top: 40px;

}

.heroImage {
  height: 70dvh;
}

.heroText {
  color: #4a0c3f;
  font-weight: bold;
  font-size: 18px;
}

.errorLoginText { 
  text-decoration: underline;
  cursor: pointer;
}

