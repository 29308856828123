.transcriptWrapper {
  border: 1px solid silver; 
  width: 500px; 
  padding: 10px;
  background-color: #f8f8f8;
  margin-top: 0px; 
  margin-right: 20px; 
  margin-left: 100px; 
  height: 500px; 
  border-radius: 10px; 
  box-shadow: 1px 2px 10px gray;
  overflow-y: auto;
}

.videoPlayerWrapper {
  height: 500px;
}

.answerWrapper {
  margin-top: 20px;
}

.completedResponseWrapper {
  height: 50px;
  width: 250px;
  /* border: 1px solid silver; */
  /* border-radius: 5px; */
  /* box-shadow: 1px 2px 6px gray; */
  justify-content: center;
  align-items: center;
}

.completedResponseText { 
  color: purple;
  font-size: 24px;
  font-weight: bold;
}

.completedIcon {
  height: 30px;
  width: 30px;
  margin-left: 5px;
  margin-bottom: 10px;
}