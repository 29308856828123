.avatarStyle { 
  width: 200px;
  height: 200px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  /* float: left; */
  background-size: cover;
  background-position: center center;
  border: 10px solid #199fbc ;
  
}
