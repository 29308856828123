.pageWrapper {
  margin-left: 40px;
  margin-top: 40px;
}

.helperTextWrapper {
  width: 35dvw;
  margin-bottom: 20px;
}

.headingWrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.headingText {
  font-size: 24px;
  color: black;
  text-decoration: underline; 
  text-decoration-color: #72c6d8; 
  text-underline-offset: 10px; 
  text-decoration-thickness: 5px; 
}

.accordianHeading {
  font-size: 18px;
  color: #4a0c3f;
}


