.resumeUploadButton {
  box-shadow: 1px 3px 2px gray; 
  height: 35px; 
  width: 150px; 
  border-radius: 5; 
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
}

.resumeUploadButtonText {
  color: #199fbc;
  font-size: 16px;
  font-weight: bold;
}

.technicalFocusAreaLabel {
  font-size: 16px;
  color: black;
  text-decoration: underline; 
  text-decoration-color: #72c6d8; 
  text-underline-offset: 10px; 
  text-decoration-thickness: 5px; 
  padding-bottom: 10px;
}