.mainWrapper {
  display: flex;
  flex: 1;  
}

.logoWrapper {
  margin-top: 1dvw;
  padding-left: 1dvw;
}

.logoStyle {
  height: 50px;
}

.stepWrapper {
  padding-top: 20px;
  width: 65dvw;
  justify-content: center;
  align-items: center;
}

.stateWrapper {
  margin-bottom: 20px;
}

.requiredText {
  color: red;
  font-size: 20px;
}

.inputStyle {
  margin-top: 0px;
}

.emailWrapper {
  align-items: center;
  width: 100%;
}

.emailInputStyle {
  width: 640px;
}

.uniquenessWrapper {
  position: relative;
  left: 10px;
  top: 15px;
  width: 10px;
}