
.bodyWrapper {
  
  display: flex;
  justify-content: center;
  padding-top: 20dvh;
}

.emailOuterWrapper {
  align-items: center;
}

.formWrapper {
  margin-top: 10px;
  width: 550px;
}

.emailInputWrapper {
  width: 100%;
}

.emailWrapper {
  width: 490px;
}

.emailInputStyle {
  width: 500px;
}

.uniquenessWrapper {
  position: relative;
  left: 16px;
  top: 10px;
  height: 40px;
  width: 5%;
}

.passwordWrapper {
  width: 500px;
}

.inputStyle {
  margin-top: 0px;
  width: 500px;
}



.logoWrapper {
  height: 100px;
  width: 150px;
  margin-bottom: 20px;
}

.logoStyle {
  height: 20dvw;
  width: 30dvw;
}

.buttonWrapper {
  margin-top: 30px;
}

