.pencilIconButtonStyle {
  width: 20dvh;
  color: purple;
}

.textFieldStyle {
  width: 40dvw;
}

.commentSubmitButtonWrapper {
  margin-top: 20px;
  width: 22dvw;
  justify-content: space-between;
}

.commentSectionWrapper {
  background-color: #f2f7fb;
  margin-bottom: 10px;
  margin-left: 40px;
  width: 50dvw;
  padding: 15px;
  border-radius: 10px;
}

.commentSectionRowItem {
  margin-bottom: 5px;
}

.commentRow {
  margin-bottom: -5px;
}

.commentAuthorText {
  font-weight: bold;
  padding-left: 5px;
}

.commentText {
  font-weight: 300;
}

.commentDateSentText {
  padding-left: 30px;
  font-size: 14px;
  color: purple
}

.actionButtonWrapper {
  margin-top: 40px;
  width: 30dvw;
  justify-content: space-between;
}

.commentButtonText {
  color: purple;
}