.emptyStateWrapper {
  width: 30dvw;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.emptyStateText { 
  font-size: 18px;
  text-align: center;
}

