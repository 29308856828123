.mainWrapper {
  display: flex;
  flex: 1;
}

.bodyWrapper {
  padding-left: 100px;
}

.actionButtonWrapper {
  width: 50%; 
  justify-content: space-between;
  margin-top: 0px;
}

.previousButton {
  height: 50px; 
  width: 150px; 
  background-color: #E8E8E8;
  color: black;
}

.previousButtonInactive {
  height: 50px; 
  width: 150px; 
  background-color: #f7f7f7;
  color: black;
}

.muiActive {
  color: #199fbc
}

.muiInactive {
  color: black;
}

.loaderWrapper {
  padding-top: 30px;
}

.inactiveFinishButton {
  height: 50px; 
  width: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 5px; 
  background-color: #f7f7f7;
  :hover {
    background-color: #f7f7f7;
  }
}

.activeFinishButton {
  height: 50px; 
  width: 150px; 
  background-color: #F05454;
  justify-content: center;
  border-radius: 5px; 
  align-items: center;
  :hover {
    background-color: #F05454;
    cursor: pointer;
  }
}

.inactiveButtonText {
 color: gray;
}

.activeButtonText {
  color: white;
  font-weight: bold;
}

.evaluatingResponseText {
  color: #4a0c3f;
  font-weight: 600;
  font-size: 22px;
  padding-bottom: 10px;
}

