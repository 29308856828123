.addOnWrapper {
  border: 1px solid silver;
  padding: 10px;
  width: 200px;
  margin: 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.addOnHeading {
  font-size: 18px;
  font-weight: bold;
  color: #222831;
}

.addOnPrice {
  font-size: 18px;
  font-weight: 700;
  color: #4a0c3f;

}

.addOnDetails {
  font-size: 16px;
  color: black;
  font-weight: 400;
  text-align: center;
}

.addOnBuyNowButton {
  background-color: black;
  height: 25px;
  color: white;
  margin-top: 5px;
}
