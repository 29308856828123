.uploadHeader {
  text-decoration: underline; 
  text-decoration-color: #72c6d8; 
  text-underline-offset: 10px; 
  text-decoration-thickness: 5px; 
  font-size: 20px
}

.bodyWrapper {
  margin-top: 20px;
  width: 100%;
}

.resumeUploadButton {
  height: 45px;
  width: 360px;
  background-color: white; 
  color: #4a0c3f;
}

.fileNameLabel {
  color: #4a0c3f;
  padding-right: 10px;
}

