.bodyWrapper {
  padding-top: 20dvh;
  display: flex;
  justify-content: center;
}

.innerWrapper {
  justify-content: center;
}

.inputWrapper {
  margin-top: 20px;
}

.inputStyle {
  margin: 10px;
  width: 120px;
  border: 1px solid purple;
  border-radius: 10px;
}

.submitButtonWrapper {
  margin-top:  30px;
}

.helperText {
  font-size: 20px;
}

.logoStyle {
  height: 60px;
  width: 30%;
  margin-bottom: 20px;
}