.inputWrapper {
  margin-top: 10px;
  color: #4a0c3f;
  font-weight: 600;
}

.checkboxWrapper {
  margin-top: 20px;
  justify-content: center;
}

.inputStyle {
  height: 25px;
  width: 25px;
}