.pageWrapper {
  display: flex;
  padding-bottom: 50px;
}

.mainBodyWrapper {
  height: 100dvh;
  padding: 25px;
  margin-top: 20px;
  width: '100dsw';
  /* background-color: silver; */
}

.leftColumn {
  /* background-color: blue; */
  width: 65dvw;
}

.rightColumn {
  /* background-color: pink; */
  width: 15dvw;
  border-left: 1px solid lightgray;
  padding-left: 10px;
}

.selectStyle { 
  border-width: 1;
  border-radius: 5px;
  width: 300px;
}

.textInputStyle {
  border-width: 1;
  border-radius: 10px;
  width: 300px;
}

.assessmentDetailsWrapper {
  margin-top: 20px;
  align-items: flex-start;
}

.rightColumnAssessmentDetailsWrapper {
  margin-left: 5dvw;
}

.radioButtonWrapper {
  display: flex;
  gap: 0;
  align-items: center;
}

.interviewQuestionsWrapper {
  margin-top: 20px;
}

.tableWrapper {
  margin-top: 30px;
  padding-right: 20px;
}

.submitButtonWrapper {
  margin-top: 30px;
  padding-bottom: 50px;
}

.focusAreaFilterWrapper {
  align-items: flex-start;
  padding-top: 20px;
}

.focusAreaFilterText { 
  font-weight: bold;
  font-size: 16px;
}