.avatar-style {
  width: 150px;
  height: 150px;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 50%;
  -moz-background-clip: padding;
  border-radius: 50%;
  background-clip: padding-box;
  margin: 7px 0 0 5px;
  background-size: cover;
  background-position: center center;
}

.manualResumeInputContainer {
  width: 28svw;
  height: 50px;
  justify-content: space-between; 
}

.manualInputWrapper {
  border: 1px solid silver;
  box-shadow: 1px 3px 2px gray;
  border-radius: 5px;
  padding: 10px;
}

.actionButtonWrapper {
  width: 10dvw;
  margin: 20px;
}

.resumeActionButtonStyle {
  height: 25px;
  margin-left: 10px;
  color: #4a0c3f;
  border: 1px solid gray;
  box-shadow: 1px 2px 4x 2px silver;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
}

.resumeButtonText {
  color: #4a0c3f;
  font-size: 14px;
}

.resumeUploadWrapper {
  margin-top: 30px;
  align-items: center;
}

.buttonItems {
  justify-content: space-between;
  align-items: center;
}

.triangleIcon {
  height: 20px;
  width: 20px;
}

.uploadsWrapper {
  margin-top: 100px;
  margin-left: 40px;
  padding-bottom: 60px;
}

.headerText {
  text-decoration: underline; 
  text-decoration-color: #72c6d8;
  text-underline-offset: 10px; 
  text-decoration-thickness: 5px; 
  font-size: 20px;
}

.resumeWrapper {
  margin-top: 20px;
}

.helperTextWrapper {
  margin-top: 30px;
  margin-bottom: 2px;
  width: 35dvw;
}
.helperText {
  font-size: 18px;
}

.horizontalLine {
  width: 70dvw;
  margin-bottom: 30px;
}

.emptyStateWrapper {
  width: 15dvw;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.emptyStateText { 
  font-size: 18px;
  text-align: center;
}