.highFiveWrapper {
  width: 98%;
  padding: 5px;
}

.highFiveDateText {
  
  color: #454545;
  font-size: 12px;
  font-weight: 500;
}

.highFiveSenderText {
  font-size: 16px;
  font-weight: 600;
  color: #4a0c3f;
}

.highFiveValue {
  font-size: 16px;
  font-weight: 400;
  padding-left: 5px;
  color: black;
}