.activeInterviewWrapper {
  width: '90%';
  justify-content: space-between;
}

.creditsWrapper {
  border: 1px solid #E8E8E8;
   /* width: 200px;  */
   justify-content: center;
   align-items: center;
   height: 130px;
   border-radius: 10px;
   padding: 15px; 
   box-shadow: 1px 2px 10px 2px silver;
}

.buyMoreButtonText {
  font-size: 18px;
  font-weight: bold;
  color: #F05454;
}

.interviewTypeTableText {
  font-size: 16px;
  color: #4a0c3f;
  &:hover {
    cursor: pointer;
  }
}

.interviewDateTableText {
  font-size: 16px;
  color: #4a0c3f;
  &:hover {
    cursor: default;
  }
}

.loaderWrapper {
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.activeAssessmentWrapper {
  margin-top: 50px;
}