.emptyStateWrapper {
  width: 30dvw;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.emptyStateText { 
  font-size: 18px;
  text-align: center;
}

.interviewTypeTableText {
  font-size: 16px;
  color: #4a0c3f;
  padding-left: 20px;
  &:hover {
    cursor: pointer;
  }

}

.interviewDateTableText {
  font-size: 16px;
  color: #4a0c3f;
  &:hover {
    cursor: default;
  }
}

.loaderWrapper {
  width: 100%;
  height: 150px;
  margin-top: 20%;
  margin-left: 50%;
}

.closeButtonWrapper {
  align-items: flex-end;
}

.closeButton {
 color: #4a0c3f;
}