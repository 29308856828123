.wrapper {
  display: flex;
  flex: 1;
  padding-bottom: 50px;
}

.assessmentWrapper {
  padding-left: 100px;
  margin-top: 50px;
}

.assessmentActionButtonRow {
  width: 70dvw;
  align-items: flex-end;
}

.assessmentTableWrapper {
  margin-top: 30px;
}

.selectCohortWrapper {
  width: 20dvw;
}