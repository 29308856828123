.tileWrapper {
  width: 400px;
  height: 50px;
  border: 1px solid silver;
  box-shadow: 1px 1px 4px gray;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.fileNameWrapper {
  padding-left: 10px;
  width: 350px;
}

.actionButtonWrapper {
  align-items: flex-end;
  padding-right: 10px
}

.actionButton {
  justify-content: center;
  align-items: center;
  height: 25px;

  :hover {
    cursor: pointer;
  }
}

.iconStyle {
  height: 25px;
  width: 25px;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: grab;
  }
}